<!-- 入库列表 -->

<template>
  <div class="enterList">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="enterOrderSearch" placeholder="搜索入库单号" suffix-icon="el-icon-search" clearable @change="onSearch"></el-input>
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSelectData" clearable value-format="yyyy-MM-dd">
        </el-date-picker>
        <el-button class="btn" type="primary" plain @click="downloadTemplate">下载模板</el-button>
        <el-button class="btn" type="primary" @click="importShow = true">导入</el-button>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="orderNum" label="入库单号"></el-table-column>
          <el-table-column show-overflow-tooltip prop="createdUserName" label="入库人员"></el-table-column>
          <el-table-column show-overflow-tooltip prop="createdTime" label="入库时间"></el-table-column>
          <el-table-column show-overflow-tooltip prop="inStatus" label="入库状态">
            <template slot-scope="scope">
              {{ scope.row.inStatus == '1' ? '入库完成' : '待入库' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center">
            <template slot-scope="scope">
              <el-button plain type="primary" @click="handleCheck(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>


    <!-- 导入申请弹窗 -->
    <el-dialog title="导入" :visible.sync="importShow" :before-close="onImportCancel">
      <el-form label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="上传附件" required>
              <el-upload :action="apiBaseUrl" :headers="apiHeaders" :file-list="importFiles" accept=".xlsx,.xls" :on-change="beforeUpload" :auto-upload="false" :limit="1" :on-exceed="handleExceed" :on-remove="handleRemove">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip" style="color: red;">提示：只能上传"xlsx"、"xls"文件，且不超过5MB</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onImportCancel">关 闭</el-button>
        <el-button type="primary" @click="submitFormImport('importRuleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      enterOrderSearch: "", // 搜索入库单号
      dataValue: "", // 选择时间
      // 表格数据
      tableData: [],
      // 弹窗数据
      dialogVisible: false, // 弹窗显隐
      // 导入弹窗数据
      apiBaseUrl: this.$axios.defaults.baseURL + this.$api.importInventoryInProduct,
      apiHeaders: {
        token: this.$store.state.token,
      },
      importShow: false,
      importFiles: [],  //文件列表
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    };
  },
  created () {
    this.getInventoryInOrderList();
  },
  methods: {
    // 获取列表
    getInventoryInOrderList () {
      this.loading = true;
      let params = {
        orderNum: this.enterOrderSearch || null, // 搜索入库单号
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      // 时间区间（开始时间-结束时间）
      if (this.dataValue && this.dataValue.length === 2) {
        params.startTime = this.dataValue[0];
        params.endTime = this.dataValue[1];
      } else {
        params.startTime = null;
        params.endTime = null;
      }

      this.$axios.get(this.$api.getInventoryInOrderList, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 模板下载
    downloadTemplate () {
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = "https://校园生活网.com/files/excel/商品入库单.xlsx";
      link.download = "调价申请导入模板.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 导入弹窗关闭
    onImportCancel () {
      this.importShow = false;
      this.importFiles = [];
      this.getInventoryInOrderList();
    },
    beforeUpload (file) {
      console.log(file);
      const suffixArr = ["xlsx", "xls"];
      const maxSize = 1024 * 1024 * 5;
      if (!suffixArr.includes(file.name.split(".").pop())) {
        this.$message.error(`只能上传"xlsx"、"xls"文件`);
        this.importFiles = [];
        return false;
      }
      if (file.size > maxSize) {
        this.$message.error("附件不能超过5M");
        this.importFiles = [];
        return false;
      }
      this.importFiles.push(file.raw)
    },
    handleExceed () {
      this.$message.warning(`只能上传一个文件！`);
    },
    handleRemove () {
      this.importFiles = [];
    },
    // 导入表单校验
    submitFormImport () {
      if (!this.importFiles || this.importFiles.length == 0) {
        return this.$message.warning('请先上传文件');
      }
      let parmas = new FormData()
      parmas.append('file', this.importFiles[0])
      this.$axios.post(this.$api.importInventoryInProduct, parmas).then((res) => {
        if (res.data.code == 100) {
          this.$message.success('导入成功!');
          this.onImportCancel();
        }
      })
    },

    // 查看
    handleCheck (row) {
      this.$router.push({ path: '/stock/enterManagement/enterDetail', query: { orderNum: row.orderNum } })
    },
    // 清空
    clearData () {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 时间搜索
    handleSelectData (value) {
      this.clearData();
      this.dataValue = value;
      this.getInventoryInOrderList();
    },
    // 搜索
    onSearch () {
      this.clearData();
      this.getInventoryInOrderList();
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.clearData();
      this.pageSize = e;
      this.getInventoryInOrderList();
    },
    // 换页
    handleCurrentChange (e) {
      this.tableData = [];
      this.currentPage = e;
      this.getInventoryInOrderList();
    },
  },
};
</script>

<style scoped lang="scss">
.enterList {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }

    .btn {
      margin-left: 20px;
    }
  }
}
</style>
